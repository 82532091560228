@import "@angular/cdk/overlay-prebuilt.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
app-root {
  @apply h-full;
}
body {
  @apply bg-gray-50 font-poppins;
}

label:has(input[type="radio"]) {
  @apply cursor-pointer border border-gray-200 has-[:active]:ring has-[:checked]:ring has-[:active]:ring-brand-500/25 has-[:checked]:ring-brand-600;
}
label:has(input[type="checkbox"]) {
  @apply cursor-pointer border border-gray-200 has-[:active]:ring has-[:checked]:ring has-[:active]:ring-brand-500/25 has-[:checked]:ring-brand-600;
}

button.primary {
  @apply rounded-lg bg-brand font-semibold text-white transition-all hover:bg-brand-700 active:ring active:ring-brand-500/30;
}
button.primary:disabled {
  @apply bg-gray-200 text-gray-400 hover:bg-gray-200 active:ring-0;
}
button:disabled {
  @apply cursor-default;
}
button.secondary {
  @apply rounded-lg border border-gray-300 bg-white px-4 py-2 font-semibold text-brand-700 shadow-sm transition-all hover:bg-brand-50 hover:text-brand-800 active:ring active:ring-brand-500/30;
}
button.secondary:disabled {
  @apply text-gray-300 hover:bg-white active:ring-0;
}

input[type="text"],
select {
  @apply mb-4 h-10 w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:outline-none focus:ring focus:ring-brand-500/30;
}
input[type="radio"] {
  @apply mt-0.5 size-4 appearance-none rounded-full border border-gray-300 checked:border-[5px] checked:border-brand-600;
}
input[type="checkbox"] {
  @apply mt-0.5 h-5 w-5 appearance-none rounded-md border border-gray-300 checked:border-[3px] checked:border-brand-600;
}

@layer components {
  input[type="checkbox"]:checked {
    @apply bg-brand-600 bg-center bg-no-repeat;
  }
}

h1 {
  @apply text-3xl font-bold text-gray-900 md:text-5xl md:font-semibold;
}

h2 {
  @apply text-3xl font-semibold text-gray-900 md:text-4xl;
}

h3 {
  @apply text-2xl font-semibold text-gray-900 md:text-3xl;
}

h4 {
  @apply text-lg font-semibold text-gray-900 md:text-2xl;
}

/*#region MARKDOWN STYLES*/
.markdown ol {
  @apply list-inside list-decimal pl-1;
}

.markdown a {
  @apply text-brand-600 underline;
}

.markdown ul {
  @apply list-disc pl-5;
}

/*#endregion MARKDOWN STYLES*/

ui-toast {
  @apply flex-1;
}
